.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ra-rich-text-input .ql-tooltip {
    left: 0 !important;
}

.scheduleAutocomplete {
    width: 350px;
}

.scheduleAutocompleteFullwidth {
    width: 100%;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options,
.ql-snow .ql-tooltip {
    z-index: 2 !important;
}

.ra-rich-text-input .ql-editor li:not(.ql-direction-rtl)::before {
    top: 0 !important;
    font-size: inherit !important;
}

.wrapperContent {
    max-width: 1280px;
    margin-top: 2em;
}

.wrapperContent.calendarWrapper {
    max-width: 100%;
}

.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
    transform: translate(0, 1.5px) scale(0.75) !important;
}

.MuiInputLabel-filled.MuiInputLabel-marginDense {
    transform: translate(0, 21px) scale(1) !important;
}

.MuiFilledInput-root.Mui-focused, .MuiSelect-select:focus, .ra-rich-text-input .ql-editor {
    background-color: transparent !important;
}

.ra-rich-text-input .ql-editor {
    padding-left: 0 !important;
}

.NoDataLabel {
    font-size: 17px;
    padding-top: 15px;
}

.labelMenu {
    color: rgba(0, 0, 0, 0.54) !important;
    width: auto !important;
}

.labelMenu span {
    color: rgba(0, 0, 0, 0.54);
    padding-left: 16px;
}

.showSections {
    padding: 16px !important;
}

.showSections .MuiFormControl-marginNormal{
    margin-top: 8px;
}

.colorField label {
    margin-bottom: 3px;
}

.cardForm, .create-page {
    max-width: 800px;
}

.create-page > div:first-of-type{
    margin-top: 0;
}

h1 {
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    font-weight: 400;
}

.deletedChip {
    width: 70px;
}

h1.listTitle {
    margin-bottom: 0.5em;
}

.colorCode {
    display: none;
}

.showSections div {
    padding: 0;
}

.emailChangeButton > div {
    padding-left: 0;
    padding-right: 0;
}

.fieldsWidthExpand {
    width: 300px !important;
}

.previews {
    margin-right: auto;
    margin-left: auto;
    display: table;
}

.MuiTableCell-head {
    font-size: 11px !important;
}

.column-DisplayColor > div > div {
    border-radius: 50%;
}

.colorField > div > div > div > div {
    border-radius: 50%;
}

.splitButtonTd {
    text-align-last: right;
    width: 220px !important;
}

.splitButtonRight {
    text-align-last: right;
}

.singleButtonRight {
    width: 50px !important;
}

.splitButtonTdSmall {
    text-align-last: right;
    width: 150px !important;
}

.tightTd {
    width: 90px !important;
}

.resizeTd {
    width: 300px !important;
}

.fc td {
    padding: 4px !important;
}

.fc-ltr .fc-time-area .fc-chrono th  {
    text-align: center;
    font-weight: normal;
}

.fc-widget-header {
    font-weight: normal;
}

.ra-rich-text-input{
    width: 300px !important;
}
